import React, { FC } from 'react'
import CreateTrackerSimpleForm from '../CreateTrackerSimpleForm/CreateTrackerSimpleForm'
import { Badge, Container, Flex, Heading, Text } from '@radix-ui/themes'
import Analytics from '../Analytics/Analytics'
import { useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { trackersAtom } from '../../../shared/atoms'
import { useQuery } from 'react-query'
import { getAvgSavingsUserMonth } from '../Analytics/queryFunctions'

interface LandingProps {
    formRef: React.RefObject<HTMLDivElement>
}

const Landing: FC<LandingProps> = ({ formRef }) => {
    const totalSavingsUserMonthQuery = useQuery(
        'getAvgSavingsUserMonth',
        getAvgSavingsUserMonth,
        { retryOnMount: false, refetchOnWindowFocus: false }
    )
    const navigate = useNavigate()
    const setTrackers = useSetRecoilState(trackersAtom)
    return (
        <Container size="3">
            <Flex
                direction="column"
                justify="between"
                align="center"
                className="h-full sm:h-dvh w-full p-4"
                ref={formRef}
            >
                <div></div>
                <Flex
                    direction="column"
                    gap="2"
                    justify="center"
                    align="center"
                    className="pt-8"
                >
                    {/* Title */}
                    <Flex
                        direction="column"
                        gap="4"
                        justify="center"
                        align="center"
                    >
                        <Heading size="9" weight="medium" align="center">
                            Price tracking made easy
                        </Heading>
                        <Text className="text-center">
                            PriceTracker found{' '}
                            <Badge color="blue">
                                <strong className="text-base">
                                    $
                                    {(
                                        totalSavingsUserMonthQuery?.data || 0
                                    ).toFixed(2)}
                                </strong>
                            </Badge>{' '}
                            savings on average per user{' '}
                            <strong>last month</strong>.
                            <br /> Stop wasting time checking prices. I will do
                            it for you.
                        </Text>
                    </Flex>
                    {/* Form */}
                    <CreateTrackerSimpleForm
                        onSuccess={(newTracker) => {
                            setTrackers((prev) => {
                                return [...prev, newTracker]
                            })
                            navigate('/trackers')
                        }}
                    />
                </Flex>
                <div className="pt-8 sm:pt-0">
                    <Analytics />
                </div>
            </Flex>
        </Container>
    )
}

export default Landing
