export const getTotalUsers = async () => {
    const response = await fetch('/api/v1/analytics/total-users')
    if (!response.ok) {
        throw new Error('Failed to fetch data')
    }
    return response.json()
}

export const getTotalTrackers = async () => {
    const response = await fetch('/api/v1/analytics/total-trackers')
    if (!response.ok) {
        throw new Error('Failed to fetch data')
    }
    return response.json()
}

export const getTotalNotifications = async () => {
    const response = await fetch('/api/v1/analytics/total-notifications')
    if (!response.ok) {
        throw new Error('Failed to fetch data')
    }
    return response.json()
}

export const getTotalDiscountFound = async () => {
    const response = await fetch('/api/v1/analytics/total-discount-found')
    if (!response.ok) {
        throw new Error('Failed to fetch data')
    }
    return response.json()
}

export const getAvgSavingsUserMonth = async () => {
    const response = await fetch('/api/v1/analytics/avg-savings-user-month')
    if (!response.ok) {
        throw new Error('Failed to fetch data')
    }
    return response.json()
}
