import { Flex, Grid, Link, Text, Avatar } from '@radix-ui/themes'
import { FC } from 'react'
import React from 'react'

const Footer: FC = () => {
    return (
        <Flex>
            <footer className="flex justify-center items-center flex-col w-full h-full py-12 px-4 sm:px-16 bg-[var(--slate-3)] gap-6">
                <Grid
                    columns={{ xs: '1', md: '3' }}
                    gap="8"
                    justify="between"
                    className="w-full"
                >
                    <Flex direction="column" gap="3">
                        <Text size="3" weight="bold">
                            <Flex gap="2" align="center">
                                <img
                                    src="/icon192.png"
                                    className="h-6"
                                    alt="logo"
                                ></img>{' '}
                                <strong>PriceTracker</strong>
                            </Flex>
                        </Text>
                        <Text size="1" weight="regular">
                            Price Tracking made easy. Stop wasting time checking
                            prices. No more missed deals. I'll do it for you.
                        </Text>
                        <Text size="1" weight="light">
                            © 2024 PriceTracker. All rights reserved.
                        </Text>
                    </Flex>
                    <Flex direction="column" gap="3">
                        <Text size="3" weight="bold" color="gray">
                            Links
                        </Text>
                        <Link href="/" color="gray" size="2">
                            Home
                        </Link>
                        <Link href="/trackers" color="gray" size="2">
                            Trackers
                        </Link>
                        <Link href="/prices" color="gray" size="2">
                            Prices
                        </Link>
                        <Link href="/faq" color="gray" size="2">
                            FAQ
                        </Link>
                        <Link href="/blog" color="gray" size="2">
                            Blog
                        </Link>
                        <Link
                            href="mailto:gabriel@pricetracker.cc"
                            color="gray"
                            size="2"
                        >
                            Support
                        </Link>
                    </Flex>
                    <Flex direction="column" gap="3">
                        <Text size="3" weight="bold" color="gray">
                            Legal
                        </Text>
                        <Link href="/tos" color="gray" size="2">
                            Terms of Service
                        </Link>
                        <Link href="/privacy-policy" color="gray" size="2">
                            Privacy Policy
                        </Link>
                    </Flex>
                </Grid>
                <Flex
                    className="w-full flex-col sm:flex-row"
                    align="center"
                    gap="3"
                >
                    {/* <Avatar
                        fallback="GH"
                        size="2"
                        src="/avatar.png"
                        radius="full"
                    />
                    <Text size="2" className="text-center sm:text-left">
                        Hey 👋 I'm{' '}
                        <Link
                            href="https://www.linkedin.com/in/gabriel-hall-abreu-a6b805206/"
                            target="_blank"
                            size="2"
                            className="text-[var(--slate-12)]"
                            underline="always"
                        >
                            Gabriel
                        </Link>
                        , the creator of PriceTracker.
                    </Text> */}
                </Flex>
            </footer>
        </Flex>
    )
}

export default Footer
